<template>
  <div style="height: 100%;">
    <iframe id="marketReport" width="100%"
      frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" SameSite="None" allowtransparency="yes"
      :src="showUrl"></iframe>
  </div>
</template>

<script>
export default {
  name: 'marketReportComponent',
  data () {
    return ({
      showUrl: '',
      publisherId: this.$store.getters.token.userInfo.publisherId,
      reportUrl: 'https://oohbiapi.oohforce.com:25025/webroot/decision/app/package/?preview=false&appId=111a3e2f-35f2-2c6e-cc6b-e82da176c8e1&ssoToken=',
      tokens: [
        { publisherIds: [13], name: '青岛青铁商业发展有限公司', value: 'SRdBtBCDlOjKC1Jz%2FQIVqYuODKToqNiuSabi6sjEtMl3uZKy3amIn86Bp2XvuWxcf5oj%2FQWDyT1ndWazrjpCO9zdtNIGpxVTyVq%2BYHyGWa%2F099gItWTI%2FwVNYdiulugBJ9H5RcHlNbvWFcriWpfL6I25XUP6WpjAiD8XDSe5IqK7kC6fxsOswDz4AGgGu8IXxlF28K%2FSmJY3vD%2BdqR9fn8VO3xAxz1qbK%2Bty94uwyKIpCB%2BgaVjRJ5NFw3K7Rc%2FVKKTzDlMLM0CILJPdQDcBvdjWvTH%2FkDWblUfkxao3%2BfRLow4m6I7yyt7sg1xDsGrmNnoDamNd8j4unMm41f9PeQ%3D%3D' },
        { publisherIds: [21], name: '北京市轨道交通运营管理有限公司', value: 'W0jQbt8Kj%2Fr9Fk%2BgcBaMzYEBsfov%2B4HXc0U92T7hiiFSk%2F%2Fvh1EoPySRVNBDuUPD5PjV2Ni9DvCNvHR70Lv3klHWIc2slHpvRhU%2FsGNUVORFgEAdM2EDIChau9ybIx9mHgt5VLySHra3zNoGm2ggiDir4iXH7wFPRgA4D8zSI%2FHUFGsQyRv5FGYcYuJ5s3NMhBdX1DeCV47LdSQGUabb27WpH2B5pfMuNzfdjYlIz%2F7gxdYb1EqFt9TPlxvE0%2F6KQtu%2FmUG8avHRkBY8B2GdA29eWjIcT3tmwHOuGUHEu%2FHuWBAoxdu10Axdx%2BuZ9wD1%2BJfw6YX9yspueH%2BCOYm%2FYQ%3D%3D' },
        { publisherIds: [18, 30], name: '北京地铁资源开发经营有限公司', value: 'Sr1NioBBG4aQMuMA8J0BaV%2FN3iHRvwOKbtM6CK%2FnUseMQfZpz%2BKB4eHH1MYt6rGHs7LIfWumcNQPyOI3RROfUbinUpHfDmtQb6vAmbjJczvQ%2Fcfd214EPGb4oTnOCrwaNhm5NVi4wjF35Hj6Pai4%2Bsa3rG8UR90zwFAeiMbD3zq1qkfqOk5ZukF8fvX5V6Xm9v74N17O1jKEYYeemO%2FNV0W2j8nHff54CkRISUB1ujP7YfTyZcf8sSP7pxzCvXKrpNBzfDfAaluvGZdVGNdWeeu8fXngWE0jZFf12gFCD5IBOuVRRvCHc1KF%2FY0AZi3cpyBify2wof2PXWvwdrrEyQ%3D%3D' },
        { publisherIds: [6], name: '宁波市轨道交通物产置业有限公司', value: 'k7fqAZJOiskrxSZu5c9xV%2FTf%2F9hYB4GYPIfvRsWh9OyiGcRsJzig%2F32luiMiXUFmEG4dEEhmwxh9HhjNQEke%2FIThOEOE3BEz%2FAYBS5gdYIqn%2Bd4FkAYqa3AnKOVhaSV2h5dlNRiU6Adn52jCCgXJerJzNINCwu67GfqDR%2BUm29%2FVNUk8l376m8Xxv%2B0BAJbOr4zPZvvBe3V4Yk3elH3KJ0jhXROWDAWvR%2F3iZ4yCN6jumYGGb%2BcMBOWJucT%2FsY89JjkiqSJBu0%2Fv%2FI5e8wKLCIp%2BzBH29n6313Lit1OE8boeZ99pYXPfJZC018%2BJrQMU02PDyL7gea2CJulk7MyvMA%3D%3D' },
        { publisherIds: [28], name: '福州地铁传媒有限公司', value: 'W4A5e7tY39O5pkdxf0XC692HNiY9bqrJU4nVPcRDxpPQNVd86r5qyj860sILBPQdDUSjKbicnVKb37CbUsMdA6td%2B6f%2B8xYEYjV2bRLnbd%2BSDrHk3z3kfwkczomRL2Bz45MO46t2iOdgeqHtfyY39esVEVgIv0gskaOTzE9%2F18ZdtNSwVaauQoP76tKoTAoiPmyFsge%2FIeMRtrVyNrd050UUvGclJNNEVqTDlCe8uRyHGKg%2FeLAVvoaWfyod9%2F2J0w5Ok68nIAZYCNmeTK1qWVoDuN%2BcDwzxvRGUccaiwmNHt5AGRHWeWibSoodOsvIULfeQUOF15OV0pG0Y%2FFmhoA%3D%3D' },
        { publisherIds: [25], name: '佛山市地铁运营有限公司', value: 'IuJLU%2FpC3aYCaLzNRC6UVyMPeQ1BYsgj%2BAyFrDxv6eYKwKSRiNsM5i5tcEtN81z5CWoVPzIBPkmdwkRV9EtRSWugfbQhL9KBLqUGc%2BCAead5mwD1aNMeDEl4MSIqTmlunAvKQBRAIK5O72BtOx4yncihrQg4aRYUpp%2FBOdKmDsP1p7JjfURde2%2B5HIFptEPlgaN2PQtCSjKl1m86hKhLVpuJiZGBUu6NoqTFLMEYwtxYShFIoQA8a2M%2FhLA0h2js0IdKFuENl7zmARlvSs5zuvvnvAaL7sKXJD7Dl%2Fv0BnbW4bOtnz4joxfbXI5dA4tJROA8xIGpmCW1oLlvw7oZOQ%3D%3D' },
        { publisherIds: [26], name: '云南京建轨道交通投资建设有限公司', value: 'fwouUiMsMNDpGjtH9UkxGvOgptkYawvp2twTg%2BHpDjwY6StIcRANUeKKrZWM0y%2Bgu6exDYxeNnDUvgbFGjh8ani%2FcP9%2Bt3mjzdhwL1cE1P2Tk9nBgzq7Ykum7yzj%2FPb7IZ5V9sNClSTVBZYFlfrxa0x%2B10CXI8sQjXwin1xyCAw7N5CUID7r0AwP8QmvLE%2FwXlGcCNWBN6BfolPkJeA1F0kpGEeSJfE79xRt2sTGzzKXYupn7JjaW3tBFGZlS9T8grNY2HfBQiOyCm%2Bgy4v%2B9SPlOEipcnNHQJhVl1T9m0MFaw8qJVF4GQwNHaGZD%2BgFttEJpn3kImdjGKc4AQoBgw%3D%3D' }
      ]
    })
  },
  mounted () {
    document.getElementById('marketReport').style.height = (document.body.clientHeight - 69) + 'px'
    let ssoToken = null
    for (let index = 0; index < this.tokens.length; index++) {
      if (this.tokens[index].publisherIds.includes(this.publisherId)) {
        ssoToken = this.tokens[index].value
        break
      }
    }

    this.showUrl = this.reportUrl + ssoToken
  }
}
</script>
